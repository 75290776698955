import React from 'react'

const MappingTheCubeLandscapeRedirectPage: React.FC = () => {
  return <div></div>
}

export const Head: React.FC = () => {
  return (
    <>
      <meta
        httpEquiv="Refresh"
        content="0; url='/articles/mapping-the-magic-landscape/"
      />
    </>
  )
}

export default MappingTheCubeLandscapeRedirectPage
